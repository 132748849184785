@keyframes spin {
  0%, 30% { 
    transform: rotate(0deg); 
  }
  30% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: white;
}

.spinner {
  animation: spin 4s ease-in-out infinite;
}
