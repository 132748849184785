.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 10px auto;
  width: 100%;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  background-color: white;
  border: 1px solid #ddd;
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  cursor: pointer;
}

.page-link:hover {
  background-color: #eee;
}

.active .page-link {
  background-color: black;
  color: white;
}

.previous-link, .next-link {
  background-color: white;
  border: 1px solid #ddd;
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  cursor: pointer;
}

.previous-link:hover, .next-link:hover {
  background-color: #eee;
}

.disabled-button {
  opacity: 0.5;
}

.disabled-button .page-link {
  cursor: not-allowed;
}
