body,
h2,
p {
  padding: 0;
  margin: 0;
}
* {
  box-sizing: border-box;
}

.header {
  font-size: 20px;
  padding: 16px 24px;
}

.App {
  min-height: 200vh;
  background-color: greenyellow;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
}

.contents {
  padding: 8px 24px;
   overscroll-behavior: contain;
   overflow-y: scroll;
 }

.containerInner {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 800px;
  width: 90%;
  /* margin: 10px auto; */
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
}

.button {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.openButton {
  background-color: #edf2f7;
}

.openButton:hover {
  background-color: #e2e8f0;
}

.closeButton {
  background-color: #3182ce;
  color: #fff;
}

.closeButton:hover {
  background-color: #2b6cb0;
}

.nextButton {
  background-color: transparent;
  margin-left: 4px;
}

.nextButton:hover {
  background-color: #e2e8f0;
}

.iconClose {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 30px;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  cursor: pointer;
}

.iconClose:hover {
  background-color: #e2e8f0;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
   overscroll-behavior: contain;
   overflow-y: scroll;
 }

 .container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px auto;
  overscroll-behavior: contain;
  overflow-y: scroll;
 }

