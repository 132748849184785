.fc-event {
  background: transparent;
  border: none;
  box-shadow: none;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event{
  box-shadow: none;
}

.fc .fc-timegrid-slot {
  height: 40px; /* セルの高さを50pxに設定 */
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  margin: 0 !important;
}

.fc {
  touch-action: manipulation; /* ピンチズームや二重タップズームを防ぎ、スクロールとタップのみを許可 */
}